<template>
  <div
    v-for="(condition, index) in conditions"
    :key="index"
    class="my-5 rounded-xl p-5 flex flex-col gap-5"
    v-bind="$attrs"
    :class="condition.isActive ? 'bg-tertiary' : 'bg-dark100'"
  >
    <div
      class="bg-white px-4 py-2 flex items-center justify-between my-2 w-full lg:w-1/2"
    >
      <span>{{ condition.name }}</span>
      <Switch
        color="primary"
        :is-active="condition.isActive"
        @click="
          () => {
            if (index == 0) return;
            conditions[index].isActive = !condition.isActive;
          }
        "
      />
    </div>

    <Form
      @submit.prevent="submitForm"
      v-if="condition.isActive"
      class="flex items-center justify-between gap-8"
    >
      <div class="w-full">
        <label for="ConditionTarget">{{ $t("condition-type") }}</label>
        <Field
          name="ConditionTarget"
          @change="submitForm"
          v-model="condition.ConditionTarget"
          id="ConditionTarget"
          as="select"
          class="w-full"
        >
          <option value="" disabled>{{ $t("condition-type") }}</option>
          <option
            v-for="option in conditionOptions"
            :key="option.id"
            :value="option.id"
            :label="option.name"
          >
            {{ option.name }}
          </option>
        </Field>
      </div>
      <div class="w-full">
        <label for="Type">{{ $t("type") }}</label>
        <Field name="Type" id="Type" as="select" class="w-full">
          <option value="" disabled>{{ $t("type") }}</option>
          <option :value="$t('at-least')">
            {{ $t("at-least") }}
          </option>
        </Field>
      </div>
      <div class="w-full">
        <label for="Amount">{{ $t("amount") }}</label>
        <Field
          name="Amount"
          :disabled="!condition.ConditionTarget"
          @change="submitForm"
          v-model="condition.ConditionValue"
          @mouseleave="() => submitForm()"
          id="Amount"
          type="number"
          class="input-primary"
          :placeholder="$t('amount')"
        />
      </div>
    </Form>
  </div>
</template>
<script lang="ts" setup>
import { reactive, watch } from "vue";
import { Field, Form } from "vee-validate";
import { onMounted } from "vue";

const emit = defineEmits(["submit"]);

const props = defineProps<{
  conditionOptions: ConditionOption[];
  values: {
    Condition1Target: string;
    Condition2Target: string;
    Condition3Target: string;
    Condition1Value: string;
    Condition2Value: string;
    Condition3Value: string;
  };
  types?: KeyVal[]; // $t('at-least'), $t('at-most'), $t('exactly')
}>();

watch(
  () => props.values,
  (val) => {
    conditions[0].ConditionTarget = val.Condition1Target;
    conditions[1].ConditionTarget = val.Condition2Target;
    conditions[2].ConditionTarget = val.Condition3Target;
    conditions[0].ConditionValue = val.Condition1Value;
    conditions[1].ConditionValue = val.Condition2Value;
    conditions[2].ConditionValue = val.Condition3Value;
  }
);

const conditions = reactive([
  {
    isActive: true,
    name: "Condition 1",
    ConditionTarget: "",
    ConditionValue: "",
  },
  {
    isActive: false,
    name: "Condition 2",
    ConditionTarget: "",
    ConditionValue: "",
  },
  {
    isActive: false,
    name: "Condition 3",
    ConditionTarget: "",
    ConditionValue: "",
  },
]);

const submitForm = () => {
  emit("submit", {
    Condition1Target: conditions[0].ConditionTarget,
    Condition2Target: conditions[1].ConditionTarget,
    Condition3Target: conditions[2].ConditionTarget,
    Condition1Value: conditions[0].ConditionValue,
    Condition2Value: conditions[1].ConditionValue,
    Condition3Value: conditions[2].ConditionValue,
  });
};
</script>
