<template>
  <div
    class="rounded-lg bg-secondary p-3 flex flex-col gap-2 transition-all duration-100 border border-secondary hover:border-primary"
  >
    <!-- notification header -->
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start gap-3">
        <Image
          :src="item.photo"
          class="bg-white rounded-full border h-12 w-12 border-primary/30"
        />
        <div class="flex flex-col justify-center items-start">
          <p class="text-primaryDark">{{ item.title }}</p>
          <span class="text-xs text-primary">{{
            moment(item.sentOn).fromNow()
          }}</span>
        </div>
      </div>
      <div class="relative">
        <button
          @click="toggleNotificationOption = !toggleNotificationOption"
          class="hover:bg-white rounded-xl h-12 w-12 flex items-center justify-center hover:shadow-md"
        >
          <Image :src="optionsIcon" />
        </button>
        <transition name="fade" :duration="150">
          <ul
            v-if="toggleNotificationOption"
            class="rounded-2xl p-4 flex flex-col gap-4 text-xs absolute bg-white w-[214px] z-50 transform -translate-x-3/4 text-primaryDark"
          >
            <li class="text-danger">
              <button @click="$emit('remove', item.id)">
                {{ $t("remove-notification") }}
              </button>
            </li>
            <li>
              <span class="bg-primaryDark h-4 w-4 rounded-full"></span
              ><span> Mark As unread</span>
            </li>
            <li>
              <router-link :to="{ name: 'admin-notification-controls' }">{{
                $t("notification-settings")
              }}</router-link>
            </li>
            <li class="flex items-center justify-between">
              <span>{{ $t("notification-sound") }}</span>
              <Switch
                @click="toggleNotificationSound"
                :is-active="notificationSound == '1'"
              />
            </li>
          </ul>
        </transition>
      </div>
    </div>
    <!-- notification body -->
    <div>
      <p class="text-primaryDark text-sm">
        {{ item.content }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import optionsIcon from "@/assets/icons/options.svg";
import moment from "moment";
import { ref } from "vue";

const toggleNotificationOption = ref(false);
const notificationSound = ref(localStorage.getItem("notificationSound"));

defineProps<{
  item: MyNotification;
}>();

const toggleNotificationSound = () => {
  notificationSound.value = notificationSound.value == "1" ? "0" : "1";
  localStorage.setItem("notificationSound", notificationSound.value);
};
</script>
