import { t } from '@/core/i18n'


const API_URL = '/v1.0/management/Imports'

const MODULE_TITLE = t('quiz-management')

const NAME = 'ImportQuestions'






export default {
  API_URL,
  NAME,
  MODULE_TITLE,
}
