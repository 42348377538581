import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import { BugReportCategory, BugReportStatus } from "@/core/enums";
const FAQs_API_URL = "v1.0/management/Settings/FAQs";
const ISSUES_API_URL = "v1.0/management/Issues";
const SUBMITTED_QUESTIONS_API_URL = "/v1.0/management/Suggestions";

const getStatusName = (status: BugReportStatus) => {
  switch (status) {
    case BugReportStatus.OnHold:
      return t("on-hold");
    case BugReportStatus.Solved:
      return t("solved-0");
    case BugReportStatus.Waiting:
      return t("waiting-0");
    default:
      return "";
  }
};

const route: RouteRecordRaw = {
  path: "/support-faq/",
  name: "support-faq",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("SupportFAQ"), routeName: "support-faq" }],
  },
  children: [
    {
      path: "FAQ",
      name: "FAQ",
      component: () => import("./screens/faq/FAQ.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("FAQ"), routeName: "FAQ" },
        ],
      },
    },
    {
      path: "CreateFaq",
      name: "CreateFaq",
      component: () => import("./screens/faq/faqForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("FAQ"), routeName: "FAQ" },
          { title: t("create-faq"), routeName: "CreateFaq" },
        ],
      },
    },
    {
      path: "UpdateFaq/:id",
      name: "UpdateFaq",
      component: () => import("./screens/faq/faqForm.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("FAQ"), routeName: "FAQ" },
          { title: t("update-faq"), routeName: "UpdateFaq" },
        ],
      },
    },

    {
      path: "BUG-Report-List",
      name: "BUGReportList",
      component: () => import("./screens/BUGReportList.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("BUGReportList"), routeName: "BUGReportList" },
        ],
      },
    },
    {
      path: "BugReportDetails/:id",
      name: "BugReportDetails",
      component: () => import("./screens/BugReportDetails.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("BUGReportList"), routeName: "BUGReportList" },
          { title: t("bug-report-details"), routeName: "BugReportDetails" },
        ],
      },
    },
    {
      path: "Technical-Issues-List",
      name: "TechnicalIssuesList",
      component: () => import("./screens/TechnicalIssuesList.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("TechnicalIssuesList"), routeName: "TechnicalIssuesList" },
        ],
      },
    },
    {
      path: "Support-issues-List",
      name: "SupportIssuesList",
      component: () => import("./screens/SupportIssuesList.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("SupportIssuesList"), routeName: "SupportIssuesList" },
        ],
      },
    },
    {
      path: "Submitted-Questions",
      name: "SubmittedQuestions",
      component: () =>
        import("./screens/submitedQuestions/SubmitedQuestions.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("SubmittedQuestions"), routeName: "SubmittedQuestions" },
        ],
      },
    },
    {
      path: "Submitted-Question-Details/:id",
      name: "SubmittedQuestionDetails",
      component: () =>
        import("./screens/submitedQuestions/SubmittedQuestionDetails.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("SubmittedQuestions"), routeName: "SubmittedQuestions" },
          {
            title: t("submitted-question-details"),
            routeName: "SubmittedQuestionDetails",
          },
        ],
      },
    },
    {
      path: "add-suggestion-to-pool/:gradeId/:suggestionId/:subjectId",
      name: "AddSuggestionToPool",
      component: () => import("./screens/submitedQuestions/AddToPool.vue"),
      meta: {
        breadcrumb: [
          { title: t("SupportFAQ"), routeName: "support-faq" },
          { title: t("SubmittedQuestions"), routeName: "SubmittedQuestions" },
          {
            title: t("add-suggestion-to-pool"),
            routeName: "AddSuggestionToPool",
          },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "FAQ",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

const bugsReportsTableHeader: LabelVal[] = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: t("user-name"),
    value: "User Name",
  },
  {
    label: t("user-email"),
    value: "User Email",
  },
  {
    label: t("user-phone"),
    value: "User Phone",
  },
  {
    label: t("subject"),
    value: "Subject",
  },
  {
    label: t("attachment"),
    value: "Attachment",
  },
  {
    label: t("status"),
    value: "Status",
  },
  {
    label: t("actions"),
    value: "Actions",
  },
];

const bugsReportsSettings: AppContainerSettings = {
  hasBlock: false,
  hasView: false,
  hasEdit: false,
  hasDelete: false,
  hasExport: true,
  hasFilter: true,
  hasSearch: true,
  hasNotify: false,
  notSelectable: false,
  rowsOptions: {
    hasDelete: false,
    hasEdit: false,
    hasView: true,
    hasBlock: false,
  },
};

export {
  route,
  ISSUES_API_URL,
  FAQs_API_URL,
  bugsReportsTableHeader,
  bugsReportsSettings,
  SUBMITTED_QUESTIONS_API_URL,
  getStatusName,
};
