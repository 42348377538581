import { t } from '@/core/i18n'


const API_URL = '/v1.0/management/Settings/Level-Formula'

const MODULE_TITLE = t('app-game-controls')

const NAME = 'userLevels'

const tableHeader: LabelVal[] = [
  {
    label: t('level'),
    value: 'Level',
  },
  {
    label: t('points-needed'),
    value: 'PointsNeeded',
  },
  {
    label: t('to-next-level'),
    value: 'ToNextLevel',
  },
]


export default {
  API_URL,
  tableHeader,
  NAME,
  MODULE_TITLE,
}
