<template>
  <div class="relative">
    <div v-if="badge" class="absolute z-50 rounded-full text-xs h-10 w-10 flex items-center justify-center" :class="`${badgeClass} ${badgeColorClass} ${badgeCenter ? 'left-1/2 -translate-x-1/2 -top-5' : '-left-3 -top-3'
      }`">
      <span class="text-2xl text-white">{{ badge }}</span>
    </div>
    <template v-if="src">
      <img :src="src" :class="class" :alt="alt || 'Faz'" loading="lazy" />
    </template>
    <template v-else>
      <div class="flex items-center justify-center bg-gray-50 text-neutral-300 rounded-2xl" :class="class">
        <div class="h-2/4 max-w-[50%]">
          <PlaceIcon />
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import PlaceIcon from "./PlaceIcon.vue";

type BadgeColor = "warning" | "primary" | "success" | "danger" | "black";

const props = defineProps<{
  alt?: string;
  src?: string;
  class?: string;
  badge?: number;
  badgeClass?: string;
  badgeColor?: BadgeColor;
  badgeCenter?: boolean;
}>();

const badgeColorClass = computed(() => {
  if (props.badgeColor) return `bg-${props.badgeColor}`;
  return "bg-warning";
});
</script>
