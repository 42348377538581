import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

export const MODULE_TITLE = t("exported-files");
export const API_URL = "/v1.0/management/Audits/exported-files";
export const NAME = "exported-files";

const route: RouteRecordRaw = {
  path: "/reports/",
  component: RouterView,
  name: "reports",
  meta: {
    guest: false,
    breadcrumb: [{ title: t("Reports"), routeName: "reports" }],
  },
  children: [
    {
      path: "list-of-exported-reports",
      name: "exported-reports",
      component: () => import("./screens/ExportedReports.vue"),
      meta: {
        breadcrumb: [
          { title: t("Reports"), routeName: "reports" },
          {
            title: t("exportedreports"),
            routeName: "exported-reports",
          },
        ],
      },
    },
    {
      path: "report-download-history",
      name: "report-download-history",
      component: () => import("./screens/ReportDownloadHistory.vue"),
      meta: {
        breadcrumb: [
          { title: t("Reports"), routeName: "reports" },
          {
            title: t("ReportDownloadHistory"),
            routeName: "report-download-history",
          },
        ],
      },
    },
    {
      path: "report-presets",
      name: "report-presets",
      component: () => import("./screens/ReportPresets.vue"),
      meta: {
        breadcrumb: [
          { title: t("Reports"), routeName: "reports" },
          {
            title: t("ReportPresets"),
            routeName: "report-presets",
          },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "exported-reports",
      meta: {
        breadcrumb: [{ title: t("Reports"), routeName: "reports" }],
      },
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export const settings: AppContainerSettings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: false,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: false,
  notSelectable: false,
  rowsOptions: {
    isCenter: false,
    hasView: false,
    hasBlock: false,
    hasDelete: false,
    hasEdit: false,
  },
};

export const tableHeader = [
  {
    label: t("id"),
    value: "id",
  },
  {
    label: "file Name",
    value: "file Name",
    sortKey: "ReportName",
  },
  {
    label: "Filtered",
    value: "Filtered",
    sortKey: "Filtered",
  },
  {
    label: "Exported on",
    value: "Exported on",
  },
  {
    label: "by",
    value: "by",
    sortKey: "DisplayName",
  },
  {
    label: "File Type",
    value: "File Type",
    sortKey: "FileType",
  },
  {
    label: "Action",
    value: "Action",
  },
];
export { route };
