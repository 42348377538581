<template>
  <label label="forKey" class="py-0 !px-0 border-none !m-0">
    <input
      type="date"
      v-model="modelValue"
      name="forKey"
      :disabled="disabled"
      class="!appearance-none m-0"
      v-bind="$attrs"
      format="dd/MM/yyyy"
    />
  </label>
</template>

<script lang="ts" setup>
import { useModel } from "vue";

const props = defineProps<{
  modelValue: string;
  disabled?: boolean;
}>();
const modelValue = useModel(props, "modelValue");

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();
</script>
