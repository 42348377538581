<template>
  <Modal
    :is_visible="storeModal.state.confirmationModel.isVisiable"
    @close="() => storeModal.state.confirmationModel.onCancel()"
    size="sm"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="flex items-start justify-between">
          <div class="flex flex-col gap-3 text-tertiaryDark">
            <h2 class="text-xl">
              {{ storeModal.state.confirmationModel.title }}
            </h2>
            <p>{{ storeModal.state.confirmationModel.message }}</p>
          </div>
          <Image :src="alertModalIcon" alt="alertModalIcon" loading="lazy" />
        </div>

        <div class="flex flex-col gap-2 my-2">
          <label for="adminPassword">{{ $t("admin-password") }}</label>
          <input
            type="password"
            id="adminPassword"
            name="adminPassword"
            :placeholder="$t('admin-password')"
            v-model="storeModal.state.confirmationModel.adminPassword"
            class="p-3 border-none rounded-lg bg-secondary/60"
          />
        </div>

        <div class="flex items-center justify-end mt-5">
          <div class="flex items-center justify-between gap-3">
            <button
              class="button-primary !w-fit !px-12"
              @click="() => storeModal.state.confirmationModel.onConform()"
            >
              {{ $t("yes-confirm") }}
            </button>
            <button
              class="button-primary !bg-white border border-primary !text-primary"
              @click="() => storeModal.state.confirmationModel.onCancel()"
            >
              {{ $t("no-cancel") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import alertModalIcon from "@/assets/icons/alertModalIcon.svg";

import { useModal } from "./store/modalStore";

const storeModal = useModal();
</script>
