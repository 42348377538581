import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Exams";

const MODULE_TITLE = t("exam-management");

const NAME = "Exams";

const settings: AppContainerSettings = {
  hasView: true,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  notSelectable: true,
  rowsOptions: {
    isCenter: false,
    hasView: true,
    hasBlock: false,
    hasDelete: false,
    hasEdit: false,
  },
};

const tableHeader: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("year"),
    value: "Year",
  },
  {
    label: t("semester"),
    value: "Semester",
  },
  {
    label: t("exam-type"),
    value: "ExamType",
  },
  {
    label: t("number-of-questions"),
    value: "NumberOfQuestions",
  },
  {
    label: t("completed"),
    value: "Completed",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

const createQuestionTable: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("order"),
    value: "order",
  },
  {
    label: t("type"),
    value: "type",
  },
  {
    label: t("answer"),
    value: "answer",
  },
  {
    label: t("image"),
    value: "image",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
  createQuestionTable,
};
