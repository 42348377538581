import { t } from "@/core/i18n";


const API_URL = "/v1.0/management/Admins";

const NAME = "AdminsManagement";

const settings = {
    hasView: true,
    hasExport: true,
    hasBlock: true,
    hasDelete: true,
    hasNotify: true,
    hasFilter: true,
    hasSearch: true,
    hasEdit: false,
    rowsOptions: {
        hasView: true,
        hasBlock: false,
        hasDelete: false,
        hasEdit: false,
    }
}

const tableHeader: LabelVal[] = [
    {
        label: 'id',
        value: 'id',
    },
    {
        label: t('name'),
        value: 'name',
    },
    {
        label: t('email'),
        value: 'email',
    },
    {
        label: t('phone'),
        value: 'phone',
    },
    {
        label: t('role'),
        value: 'Role',
    },

    {
        label: t('created-on'),
        value: 'created_on',
    },
    {
        label: t('action'),
        value: 'Action',
    }
]

export default {
    API_URL,
    settings,
    tableHeader,
    NAME
}
