import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Grades";

const MODULE_TITLE = t("list-of-grades");

const NAME = "grades";

const settings = {
  hasView: true,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  rowsOptions: {
    hasView: true,
    hasBlock: false,
    hasDelete: false,
    hasEdit: false,
  },
};

const tableHeader = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("grade-ar"),
    value: "nameArabic",
    sortKey: "ArabicName",
  },
  {
    label: t("grade-en"),
    value: "name",
    sortKey: "EnglishName",
  },
  {
    label: t("number-of-subjects-linked"),
    value: "noOfSubjects",
  },
  {
    label: t("number-of-users-students"),
    value: "NumberOfUsers",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
};
