<template>
  <button
    @click="handleClick"
    :disabled="disabled"
    v-bind="$attrs"
    class="px-4 py-3 rounded-lg text-primary bg-tertiary disabled:text-white"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-unlock"
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
      <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
    </svg>
  </button>
</template>
<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["click"]);

function handleClick() {
  emit("click");
}
</script>
