import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import students from "./students";
import parents from "./parents";
import teachers from "./teachers";
import allUsers from "./allUsers";

// icons
import facebookIcon from "@/assets/icons/facebook.svg";
import ManagementIcon from "@/assets/icons/ManagementIcon.svg";
import bedayaIcon from "@/assets/icons/bedayaIcon.svg";
import googleIcon from "@/assets/icons/google.svg";
import { UserTypeIcon } from "@/core/enums";

const MODULE_TITLE = t("users-management") satisfies string;

const route: RouteRecordRaw = {
  path: "/users/",
  name: "users",
  redirect: "/users/all-users",
  component: RouterView,
  meta: {
    // permission: 1 << 0,
    breadcrumb: [{ title: t("Users"), routeName: "users" }],
  },
  children: [
    {
      path: "all-users",
      name: "all-users",
      component: () => import("../screens/AllUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("AllUsers"), routeName: "all-users" },
        ],
      },
    },
    {
      path: "student-users",
      name: "student-users",
      component: () => import("../screens/StudentUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("list-of-students"), routeName: "student-users" },
        ],
      },
    },
    {
      path: "parent-users",
      name: "parent-users",
      component: () => import("../screens/ParentUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("ParentUsers"), routeName: "parent-users" },
        ],
      },
    },

    {
      path: "teacher-users",
      name: "teacher-users",
      component: () => import("../screens/TeacherUsers.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("teacherUsers"), routeName: "teacher-users" },
        ],
      },
    },
    {
      path: "user-profile/:id",
      name: "userProfile",
      component: () => import("../screens/UserProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("user-profile"), routeName: "userProfile" },
        ],
      },
    },
    {
      path: "teacher-profile/:id",
      name: "teacherProfile",
      component: () => import("../screens/UserProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("teacherUsers"), routeName: "teacher-users" },
          { title: t("user-profile"), routeName: "userProfile" },
        ],
      },
    },
    {
      path: "parent-profile/:id",
      name: "parentProfile",
      component: () => import("../screens/UserProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("ParentUsers"), routeName: "parent-users" },
          { title: t("user-profile"), routeName: "userProfile" },
        ],
      },
    },
    {
      path: "edit-profile/:id",
      name: "editProfile",
      component: () => import("../screens/EditProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("admin"), routeName: "Home" },
          { title: t("user-management"), routeName: "users" },
          { title: t("user-profile"), routeName: "userProfile" },
          { title: t("edit-profile"), routeName: "editProfile" },
        ],
      },
    },
  ],
};

const userTypeIcon = {
  Facebook: facebookIcon,
  Google: googleIcon,
  Bedaya: bedayaIcon,
  Management: ManagementIcon,
};

const getUserTypeIcon = (userType: UserTypeIcon) => {
  return userTypeIcon[userType] as any;
};

export {
  route,
  MODULE_TITLE,
  students,
  parents,
  teachers,
  allUsers,
  getUserTypeIcon,
};
