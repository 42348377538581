import type { App } from "vue";
import router from "@/router";
import { createPinia } from "pinia";
import initGlobalComponents from "./components";
import i18n from "./i18n";
import Toast, { type PluginOptions, POSITION } from "vue-toastification";
import { getCurrentLocale } from "./i18n";

import "@/core/extensions";
import "@/core/utils/firebase";

// init css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "vue-slider-component/theme/default.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "vue-toastification/dist/index.css";
import "@vueform/multiselect/themes/default.css";
import "@/core/styles/tailwind.scss";
import "@/core/styles/index.scss";

import { useHttpClient } from "./network/httpClient";

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  rtl: getCurrentLocale() === "ar",
  toastDefaults: {
    success: {
      toastClassName: "bg-success text-white text-sm",
    },
    error: {
      toastClassName: "bg-danger text-white text-sm",
    },
    warning: {
      toastClassName: "bg-warning text-white text-sm",
    },
  },
};

function initializeApp(app: App) {
  // Initialize global Components
  initGlobalComponents(app);

  app.config.globalProperties.$useHttpClient = useHttpClient();

  app.use(Toast, toastOptions);
  app.use(createPinia());
  app.use(router);
  app.use(i18n);
  app.mount("#app");
}

export default initializeApp;
