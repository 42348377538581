import { ReportFileType } from "@/core/enums";
import { defineStore } from "pinia";
import { reactive, watch } from "vue";

export enum ModalState {
  Conformed = "conformed",
  Canceled = "canceled",
  Wating = "wating",
}

const useModal = defineStore("modalStore", () => {
  // define app modals here and use them in the app

  const state = reactive({
    deleteModal: {
      title: "",
      message: "",
      reason: "",
      adminPassword: "",
      isVisiable: false,
      modalState: ModalState.Wating,
      onConform: () => {
        state.deleteModal.modalState = ModalState.Conformed;
        state.deleteModal.isVisiable = false;
      },
      onCancel: () => {
        state.deleteModal.modalState = ModalState.Canceled;
        state.deleteModal.isVisiable = false;
      },
    },
    confirmationModel: {
      title: "",
      message: "",
      adminPassword: "",
      isVisiable: false,
      modalState: ModalState.Wating,
      onConform: () => {
        state.confirmationModel.modalState = ModalState.Conformed;
        state.confirmationModel.isVisiable = false;
      },
      onCancel: () => {
        state.confirmationModel.modalState = ModalState.Canceled;
        state.confirmationModel.isVisiable = false;
      },
    },
    exportFileModal: {
      title: "",
      message: "",
      type: 1,
      isVisiable: false,
      modalState: ModalState.Wating,
      onConform: () => {
        state.exportFileModal.modalState = ModalState.Conformed;
        state.exportFileModal.isVisiable = false;
      },
      onCancel: () => {
        state.exportFileModal.modalState = ModalState.Canceled;
        state.exportFileModal.isVisiable = false;
      },
    },
    alertModal: {
      isVisiable: false,
      title: "",
      message: "",
      modalState: ModalState.Wating,
      onConform: () => {
        state.alertModal.modalState = ModalState.Conformed;
        state.alertModal.isVisiable = false;
      },
      onCancel: () => {
        state.alertModal.modalState = ModalState.Canceled;
        state.alertModal.isVisiable = false;
      },
    },
    successModal: {
      isVisiable: false,
      title: "",
      message: "",
      modalState: ModalState.Wating,
      onConform: () => {
        state.successModal.modalState = ModalState.Conformed;
        state.successModal.isVisiable = false;
      },
      onCancel: () => {
        state.successModal.modalState = ModalState.Canceled;
        state.successModal.isVisiable = false;
      },
    },
  });

  const showDeleteModal = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }): Promise<{
    reason: string;
    modalState: ModalState;
    adminPassword: string;
  }> => {
    state.deleteModal.modalState = ModalState.Wating;
    state.deleteModal.title = title;
    state.deleteModal.isVisiable = true;
    state.deleteModal.message = message;
    return new Promise((resolve, reject) => {
      watch(
        () => state.deleteModal.modalState,
        (newVal) => {
          if (newVal === ModalState.Conformed) {
            resolve({
              reason: state.deleteModal.reason || "",
              adminPassword: state.deleteModal.adminPassword || "",
              modalState: state.deleteModal.modalState,
            });
          }
          if (newVal === ModalState.Canceled) {
            reject("user canceled action");
          }
        }
      );
    });
  };
  const showConfirmationModel = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }): Promise<{
    modalState: ModalState;
    adminPassword: string;
  }> => {
    state.confirmationModel.modalState = ModalState.Wating;
    state.confirmationModel.title = title;
    state.confirmationModel.isVisiable = true;
    state.confirmationModel.message = message;
    return new Promise((resolve, reject) => {
      watch(
        () => state.confirmationModel.modalState,
        (newVal) => {
          if (newVal === ModalState.Conformed) {
            resolve({
              adminPassword: state.confirmationModel.adminPassword || "",
              modalState: state.confirmationModel.modalState,
            });
          }
          if (newVal === ModalState.Canceled) {
            reject("user canceled action");
          }
        }
      );
    });
  };

  const showExportFileModal = (
    title: string,
    message: string
  ): Promise<{ type: ReportFileType; modalState: ModalState }> => {
    console.log("Fdxbl/kdfgmn");
    
    state.exportFileModal.modalState = ModalState.Wating;
    state.exportFileModal.title = title;
    state.exportFileModal.message = message;
    state.exportFileModal.isVisiable = true;
    return new Promise((resolve, reject) => {
      watch(
        () => state.exportFileModal.modalState,
        (newVal) => {
          if (newVal === ModalState.Conformed) {
            resolve({
              type: state.exportFileModal.type || 0,
              modalState: state.exportFileModal.modalState,
            });
          }
          if (newVal === ModalState.Canceled) {
            reject("user canceled action");
          }
        }
      );
    });
  };

  const showSuccessModal = (
    title: string,
    message: string
  ): Promise<{ modalState: ModalState }> => {
    state.successModal.modalState = ModalState.Wating;
    state.successModal.title = title;
    state.successModal.message = message;
    state.successModal.isVisiable = true;
    return new Promise((resolve, reject) => {
      watch(
        () => state.successModal.modalState,
        (newVal) => {
          if (newVal === ModalState.Conformed) {
            resolve({
              modalState: state.successModal.modalState,
            });
          }
          if (newVal === ModalState.Canceled) {
            reject("user canceled action");
          }
        }
      );
    });
  };

  const showAlertModal = (
    title: string,
    message: string
  ): Promise<{ modalState: ModalState }> => {
    state.alertModal.modalState = ModalState.Wating;
    state.alertModal.title = title;
    state.alertModal.message = message;
    state.alertModal.isVisiable = true;
    return new Promise((resolve, reject) => {
      watch(
        () => state.alertModal.modalState,
        (newVal) => {
          if (newVal === ModalState.Conformed) {
            resolve({
              modalState: state.alertModal.modalState,
            });
          }
          if (newVal === ModalState.Canceled) {
            reject("user canceled action");
          }
        }
      );
    });
  };

  return {
    state,
    showDeleteModal,
    showSuccessModal,
    showAlertModal,
    showExportFileModal,
    showConfirmationModel,
  };
});
export { useModal };
