<template>
  <div class="grid items-start grid-cols-12 h-screen">
    <SideBar
      :is_active="sideBarActive"
      @toggleSidebar="sideBarActive = !sideBarActive"
    />
    <div class="col-span-12 w-full lg:col-span-10 relative">
      <HeaderBar @toggleSidebar="sideBarActive = !sideBarActive" />
      <div class="flex items-start h-screen pt-[70px]">
        <MainContent />
        <NotificationBar
          :is_active="notificationBarActive"
          @toggleNotificationBar="notificationBarActive = !notificationBarActive"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NotificationBar from "./NotificationBar.vue";
import SideBar from "./SideBar.vue";
import HeaderBar from "./HeaderBar.vue";
import MainContent from "./MainContent.vue";

import { ref, onMounted, watch } from "vue";

const sideBarActive = ref(false);
const notificationBarActive = ref(
  localStorage.getItem("liveNotification") == "1" ? true : false
);

const handleResize = () => {
  if (window.innerWidth > 1024) {
    sideBarActive.value = true;
  } else {
    sideBarActive.value = false;
  }
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

watch(notificationBarActive, (val) => {
  localStorage.setItem("liveNotification", val ? "1" : "0");
});
</script>

<style></style>
