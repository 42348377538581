<template>
  <button
    v-bind="$attrs"
    @click="handleClick"
    :disabled="disabled"
    class="px-4 py-3 rounded-lg text-tertiary bg-primary disabled:text-white"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.2"
      height="22.25"
      viewBox="0 0 20.2 25.25"
    >
      <path
        id="notifications_FILL0_wght400_GRAD0_opsz48_1_"
        data-name="notifications_FILL0_wght400_GRAD0_opsz48 (1)"
        d="M160,197.462v-1.894h2.651v-9.658a7.62,7.62,0,0,1,1.562-4.719,6.733,6.733,0,0,1,4.119-2.6v-.915a1.551,1.551,0,0,1,.521-1.2,1.878,1.878,0,0,1,2.493,0,1.55,1.55,0,0,1,.521,1.2v.915a6.8,6.8,0,0,1,4.135,2.6,7.57,7.57,0,0,1,1.578,4.719v9.658h2.62v1.894ZM170.1,188.152Zm0,13.1a2.447,2.447,0,0,1-1.768-.742,2.406,2.406,0,0,1-.758-1.783h5.05a2.532,2.532,0,0,1-2.525,2.525Zm-5.555-5.681h11.142v-9.658a5.47,5.47,0,0,0-1.61-3.977,5.582,5.582,0,0,0-7.906,0,5.443,5.443,0,0,0-1.625,3.977Z"
        transform="translate(-160 -176)"
        fill="currentcolor"
      />
    </svg>
  </button>
</template>
<script setup lang="ts">


defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["click"]);

function handleClick() {
  emit("click");
}
</script>
