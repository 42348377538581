<template>
  <transition name="slide-fade">
    <div
      v-if="!isOnline"
      class="fixed bottom-12 z-50 text-white max-w-[300px] rtl:left-12 ltr:right-12 bg-danger gap-3 flex items-start justify-center rounded-lg p-5 shadow-primary"
    >
      <span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="40.95"
          height="39.75"
          viewBox="0 0 40.95 39.75"
        >
          <path
            id="signal_disconnected_FILL0_wght400_GRAD0_opsz48"
            d="M33.65,29.4,31.9,27.65a11.675,11.675,0,0,0,2.225-3.55A11,11,0,0,0,34.9,20a10.723,10.723,0,0,0-.825-4.175A11.9,11.9,0,0,0,31.75,12.25L33.5,10.5a13.1,13.1,0,0,1,2.9,4.35,13.5,13.5,0,0,1,1,5.15,13,13,0,0,1-1,5.05A15.254,15.254,0,0,1,33.65,29.4Zm-6.3-6.3L20.9,16.65a3.846,3.846,0,0,1,1.425-.9A4.868,4.868,0,0,1,24,15.45,4.524,4.524,0,0,1,28.55,20a4.662,4.662,0,0,1-.3,1.65,4.4,4.4,0,0,1-.9,1.45ZM38.25,34,36.5,32.25a19.632,19.632,0,0,0,3.675-5.675A16.672,16.672,0,0,0,41.5,20a16.884,16.884,0,0,0-1.35-6.675,18.247,18.247,0,0,0-3.8-5.675L38.1,5.9a20.124,20.124,0,0,1,4.375,6.475A19.466,19.466,0,0,1,44,20a19.161,19.161,0,0,1-1.525,7.55A21.5,21.5,0,0,1,38.25,34Zm2.4,10.95L25.5,29.8V42h-3V26.8l-9.1-9.1a4.392,4.392,0,0,0-.25,1.125Q13.1,19.4,13.1,20a11.2,11.2,0,0,0,.8,4.2,10.481,10.481,0,0,0,2.35,3.55L14.5,29.5a13.1,13.1,0,0,1-2.9-4.35,13.5,13.5,0,0,1-1-5.15,13.831,13.831,0,0,1,.175-2.225,10.858,10.858,0,0,1,.575-2.125l-3.1-3.1a17.762,17.762,0,0,0-1.3,3.625A16.333,16.333,0,0,0,6.5,20a17.16,17.16,0,0,0,1.325,6.675A17.494,17.494,0,0,0,11.65,32.35L9.9,34.1A19.73,19.73,0,0,1,4.6,15.2a21.02,21.02,0,0,1,1.75-4.55l-3.3-3.3L5.2,5.2,42.8,42.8Z"
            transform="translate(-3.05 -5.2)"
            fill="#fff"
          />
        </svg>
      </span>
      <p class="text-lg">{{ $t("no-internet-connection") }}</p>
    </div>
  </transition>

  <transition name="slide-fade">
    <div
      v-if="backOnLine"
      class="fixed bottom-12 z-50 text-white max-w-[300px] rtl:left-12 ltr:right-12 bg-success gap-3 flex items-start justify-center rounded-lg p-5 shadow-primary"
    >
      <span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="41.05"
          height="23.5"
          viewBox="0 0 41.05 23.5"
        >
          <path
            id="done_all_FILL0_wght400_GRAD0_opsz48"
            d="M14.7,35.9,3.5,24.7l2.15-2.15L14.7,31.6l2.15,2.15Zm8.5,0L12,24.7l2.15-2.15L23.2,31.6,42.4,12.4l2.15,2.15Zm0-8.5-2.15-2.15L33.9,12.4l2.15,2.15Z"
            transform="translate(-3.5 -12.4)"
            fill="#fff"
          />
        </svg>
      </span>
      <p class="text-lg">
        {{ $t("your-internet-connections-has-been-restored") }}
      </p>
    </div>
  </transition>

  <!-- <transition name="slide-fade">
        <div v-if="downlink && downlink < 1 "
            class="fixed bottom-12  z-50 text-white max-w-[300px] rtl:left-12 ltr:right-12 bg-warning gap-3 flex items-start justify-center rounded-lg p-5 shadow-primary">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="44" height="38" viewBox="0 0 44 38">
                    <path id="warning_FILL0_wght400_GRAD0_opsz48"
                        d="M2,42,24,4,46,42Zm5.2-3H40.8L24,10Zm17-2.85a1.454,1.454,0,1,0-1.075-.425A1.457,1.457,0,0,0,24.2,36.15ZM22.7,30.6h3V19.4h-3ZM24,24.5Z"
                        transform="translate(-2 -4)" fill="#fff" />
                </svg>
            </span>
            <p class="text-lg">{{ $t('internet-connection-is-weak') }}</p>
        </div>
    </transition> -->
</template>

<script setup lang="ts">
import { watch, computed, ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";

const backOnLine: Ref<boolean> = ref(false);

const isOnline = computed(() => navigator.onLine);

watch(
  () => navigator.onLine,
  (value) => {
    if (value) {
      backOnLine.value = true;
      startKillTimeOut();
    }
  }
);

const startKillTimeOut = () => {
  setTimeout(() => {
    backOnLine.value = false;
  }, 15000);
};
</script>
