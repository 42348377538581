import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Settings/Admin-Notifications";

const NAME = "AdminNotificationsManagement";

const tableHeader: LabelVal[] = [
  {
    label: t("type-of-notification"),
    value: "typeOfNotification",
  },
  {
    label: t("severity"),
    value: "Severity",
  },
  {
    label: t("my-notification"),
    value: "MyNotification",
  },
  {
    label: t("live-notification"),
    value: "LiveNotification",
  },
  {
    label: t("email-notification"),
    value: "EmailNotification",
  },
  {
    label: t("phone-notification"),
    value: "PhoneNotification",
  },
];

export default {
  NAME,
  API_URL,
  tableHeader,
};
