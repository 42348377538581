import type { App } from "vue";
import PrimaryButton from "./utils/PrimaryButton.vue";
import Divider from "./utils/Divider.vue";
import Alert from "./utils/Alert.vue";
import Table from "./utils/Table.vue";
import Modal from "./utils/modals/Modal.vue";
import Pagination from "./utils/Pagination.vue";
import Loading from "./utils/Loading.vue";
import ModuleHeader from "./utils/ModuleHeader.vue";
import ExportButton from "./utils/ExportButton.vue";
import SearchInput from "./utils/SearchInput.vue";
import CheckBox from "./utils/CheckBox.vue";
import DeleteButton from "./utils/DeleteButton.vue";
import EmptyTable from "./utils/EmptyTable.vue";
import Block from "./utils/Block.vue";
import NotifyAction from "./utils/NotifyAction.vue";
import AppContainer from "./layout/AppContainer.vue";
import BackButton from "./utils/BackButton.vue";
import Switch from "./utils/Switch.vue";
import Labeled from "./utils/Labeled.vue";
import Container from "./layout/Container.vue";
import Tabs from "./utils/Tabs.vue";
import Image from "./utils/Image/Image.vue";
import EditButton from "./utils/EditButton.vue";
import Conditions from "./utils/Conditions.vue";
import SelectInput from "./utils/SelectInput.vue";
import DropDown from "./icons/DropDown.vue";
import InputNumber from "./icons/InputNumber.vue";
import DatePicker from './utils/DatePicker.vue';
import UnBlock from './utils/UnBlock.vue';
import PasswordField from './utils/PasswordField.vue';
import SelectImageFromLibrary from './utils/SelectImageFromLibrary.vue';
import X from './icons/X.vue';

import VOtpInput from "vue3-otp-input";
import VueSlider from "vue-slider-component";
import { QuillEditor } from '@vueup/vue-quill'
function initGlobalComponents(app: App) {
  app.component("X", X);
  app.component("v-otp-input", VOtpInput);
  app.component('DatePicker', DatePicker);
  app.component('UnBlock', UnBlock);
  app.component("Divider", Divider);
  app.component("PrimaryButton", PrimaryButton);
  app.component("Alert", Alert);
  app.component("Table", Table);
  app.component("Modal", Modal);
  app.component("Pagination", Pagination);
  app.component("Loading", Loading);
  app.component("ModuleHeader", ModuleHeader);
  app.component("ExportButton", ExportButton);
  app.component("SearchInput", SearchInput);
  app.component("CheckBox", CheckBox);
  app.component("DeleteButton", DeleteButton);
  app.component("EmptyTable", EmptyTable);
  app.component("VueSlider", VueSlider);
  app.component("Block", Block);
  app.component("NotifyAction", NotifyAction);
  app.component("AppContainer", AppContainer);
  app.component("BackButton", BackButton);
  app.component("Switch", Switch);
  app.component("Labeled", Labeled);
  app.component("Container", Container);
  app.component("Tabs", Tabs);
  app.component("Image", Image);
  app.component("EditButton", EditButton);
  app.component("Conditions", Conditions);
  app.component("SelectInput", SelectInput);
  app.component("DropDownIcon", DropDown);
  app.component("InputNumberIcon", InputNumber);
  app.component('QuillEditor', QuillEditor);
  app.component('PasswordField', PasswordField);
  app.component('SelectImageFromLibrary', SelectImageFromLibrary);

}

export default initGlobalComponents;
