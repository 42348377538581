import locales from "@/locales";
import { createI18n } from "vue-i18n";


const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  fallbackLocale: localStorage.getItem('locale') || 'en', // set fallback locale
  messages: locales, // set locale messages
  globalInjection: true,
  legacy: false,
});

export const t = i18n.global.t;

export const getCurrentLocale = () => i18n.global.locale.value;

export default i18n;
