import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";
import stage from "./stage";
import exams from "./exams";
import importQuestion from "./importQuestion";
import exerciseManagement from "./exerciseManagement";

const route: RouteRecordRaw = {
  path: "/quiz-management/",
  name: "quiz-management",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("QuizManagement"), routeName: "quiz-management" }],
  },
  children: [
    {
      path: "quiz-management",
      name: "QuizManagement",
      component: () => import("../screens/QuizManagement/QuizManagement.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
        ],
      },
    },
    {
      path: "subject-pool/:gradeId/:subjectId",
      name: "SubjectPool",
      component: () => import("../screens/QuizManagement/SubjectPool.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
        ],
      },
    },
    {
      path: "create-stage/:gradeId/:subjectId",
      name: "CreateStage",
      component: () => import("../screens/QuizManagement/StageForm.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
          { title: t("stage"), routeName: "CreateStage" },
        ],
      },
    },
    {
      path: "update-stage/:gradeId/:subjectId/:stageId",
      name: "updateStage",
      component: () => import("../screens/QuizManagement/StageForm.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
          { title: t("stage"), routeName: "updateStage" },
        ],
      },
    },
    {
      path: "pool-questions/:gradeId/:subjectId/:stageId",
      name: "poolQuestions",
      component: () => import("../screens/QuizManagement/poolQuestions.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
          { title: t("pool-profile"), routeName: "poolQuestions" },
        ],
      },
    },
    {
      path: "add-new-question/:gradeId/:subjectId/:stageId",
      name: "AddNewQuestion",
      component: () => import("../screens/QuizManagement/AddNewQuestion.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
          { title: t("pool-profile"), routeName: "poolQuestions" },
          { title: t("add-new-question-0"), routeName: "AddNewQuestion" },
        ],
      },
    },
    {
      path: "quiz-management-question-form/:gradeId/:subjectId/:stageId/:language/:semester",
      name: "CreateQuestion",
      component: () => import("../screens/QuizManagement/CreateQuestion.vue"),
      meta: {
        permission: 16,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("subject-pool"), routeName: "SubjectPool" },
          { title: t("pool-profile"), routeName: "poolQuestions" },
          { title: t("add-new-question-0"), routeName: "AddNewQuestion" },
          { title: t("create-question"), routeName: "CreateQuestion" },
        ],
      },
    },
    {
      path: "exams-management",
      name: "ExamsManagement",
      component: () => import("../screens/ExamsManagement/ExamsManagement.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
        ],
      },
    },
    {
      path: "exams-list/:gradeId/:subjectId",
      name: "ExamsList",
      component: () => import("../screens/ExamsManagement/ExamsList.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
          { title: t("exams-list"), routeName: "ExamsList" },
        ],
      },
    },
    {
      path: "exam-profile/:gradeId/:subjectId/:examId",
      name: "ExamProfile",
      component: () => import("../screens/ExamsManagement/ExamProfile.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
          { title: t("exams-list"), routeName: "ExamsList" },
          { title: t("exam-profile"), routeName: "ExamProfile" },
        ],
      },
    },
    {
      path: "create-exam/:gradeId/:subjectId",
      name: "CreateNewExam",
      component: () =>
        import("../screens/ExamsManagement/CreateExam/CreateNewExam.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
          { title: t("exams-list"), routeName: "ExamsList" },
          { title: t("create-exam"), routeName: "CreateNewExam" },
        ],
      },
    },
    {
      path: "update-exam/:gradeId/:subjectId/:examId",
      name: "UpdateExam",
      component: () => import("../screens/ExamsManagement/ExamUpdate.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
          { title: t("exams-list"), routeName: "ExamsList" },
          { title: t("update-exam"), routeName: "UpdateExam" },
        ],
      },
    },
    {
      path: "create-exam-question/:gradeId/:subjectId/:examId/:questionOrder/:language/:semester",
      name: "CreateExamQuestion",
      component: () =>
        import("../screens/ExamsManagement/CreateExam/CreateQuestion.vue"),
      meta: {
        permission: 64,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ExamsManagement"), routeName: "ExamsManagement" },
          { title: t("exams-list"), routeName: "ExamsList" },
          { title: t("create-question"), routeName: "CreateExamQuestion" },
        ],
      },
    },
    {
      path: "exercise-management",
      name: "ExerciseManagement",
      component: () =>
        import("../screens/ExerciseManagement/ExerciseManagement.vue"),
      meta: {
        permission: 10101,
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
        ],
      },
    },
    // {
    //   path: "select-exercise-category/:gradeId/:subjectId",
    //   name: "SelectExerciseCategory",
    //   component: () =>
    //     import("../screens/ExerciseManagement/SelectExerciseCategory.vue"),
    //   meta: {
    //     permission: 10101,

    //     breadcrumb: [
    //       { title: t("QuizManagement"), routeName: "quiz-management" },
    //       {
    //         title: t("exercise-management"),
    //         routeName: "ExerciseManagement",
    //       },
    //       {
    //         title: t("book-exercise-list"),
    //         routeName: "SelectExerciseCategory",
    //       },
    //     ],
    //   },
    // },
    {
      path: "create-new-exercise/:gradeId/:subjectId/:exerciseType",
      name: "CreateNewExercise",
      component: () =>
        import("../screens/ExerciseManagement/CreateNewExercise.vue"),
      meta: {
        permission: 10101,

        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("create-new-exercise"),
            routeName: "CreateNewExercise",
          },
        ],
      },
    },
    {
      path: "update-exercise/:exerciseId/:gradeId/:subjectId/:exerciseType",
      name: "UpdateExercise",
      component: () =>
        import("../screens/ExerciseManagement/UpdateExercise.vue"),
      meta: {
        permission: 10101,

        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("update-new-exercise"),
            routeName: "UpdateExercise",
          },
        ],
      },
    },
    {
      path: "exercise-list/:gradeId/:subjectId/:exerciseType",
      name: "ExerciseList",
      component: () => import("../screens/ExerciseManagement/ExerciseList.vue"),
      meta: {
        permission: 10101,

        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("exercise-list-0"),
            routeName: "ExerciseList",
          },
        ],
      },
    },
    {
      path: "exercise-profile/:gradeId/:subjectId/:exerciseId/:exerciseType",
      name: "ExerciseProfile",
      component: () =>
        import("../screens/ExerciseManagement/ExerciseProfile.vue"),
      meta: {
        permission: 10101,

        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("exercise-list-0"),
            routeName: "ExerciseList",
          },
          {
            title: t("exercise-profile"),
            routeName: "ExerciseProfile",
          },
        ],
      },
    },
    {
      path: "create-exercise-question/:gradeId/:subjectId/:exerciseId/:exerciseType/:questionOrder/:language/:semester",
      name: "CreateExerciseQuestion",
      component: () =>
        import("../screens/ExerciseManagement/CreateExerciseQuestion.vue"),
      meta: {
        permission: 10101,

        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("exercise-list-0"),
            routeName: "ExerciseList",
          },
          {
            title: t("exercise-profile"),
            routeName: "ExerciseProfile",
          },
          {
            title: t("create-exercise-question"),
            routeName: "CreateExerciseQuestion",
          },
        ],
      },
    },
    {
      path: "view-questions/:gradeId/:subjectId/:exerciseType/:questionOrder/:exerciseId",
      name: "ViewQuestions",
      component: () =>
        import("../screens/ViewAndEditQuestions/ViewQuestions.vue"),
      meta: {
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("exercise-list-0"),
            routeName: "ExerciseList",
          },
          {
            title: t("exercise-profile"),
            routeName: "ExerciseProfile",
          },
          {
            title: t("view-question"),
            routeName: "ViewQuestions",
          },
        ],
      },
    },
    {
      path: "edit-question/:gradeId/:subjectId/:exerciseType/:questionId/:exerciseId/:language/:semester",
      name: "EditQuestion",
      component: () =>
        import("../screens/ViewAndEditQuestions/EditQuestion.vue"),
      meta: {
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          {
            title: t("exercise-management"),
            routeName: "ExerciseManagement",
          },
          {
            title: t("exercise-list-0"),
            routeName: "ExerciseList",
          },
          {
            title: t("exercise-profile"),
            routeName: "ExerciseProfile",
          },
          {
            title: t("edit-question"),
            routeName: "EditQuestion",
          },
        ],
      },
    },
    {
      path: "import-questions",
      name: "ImportQuestions",
      component: () => import("../screens/ImportQuestions.vue"),
      meta: {
        breadcrumb: [
          { title: t("QuizManagement"), routeName: "quiz-management" },
          { title: t("ImportQuestions"), routeName: "ImportQuestions" },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "QuizManagement",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

const subjectTableHeader = [
  {
    label: t("grade"),
    value: "grade",
  },
  {
    label: t("photo"),
    value: "Photo",
  },
  {
    label: t("number-of-quiz-pools"),
    value: "numberOfQuizPools",
  },

  {
    label: t("number-of-questions"),
    value: "numberOfQuestions",
  },
  {
    label: t("actions"),
    value: "actions",
  },
];

export {
  route,
  stage,
  exams,
  importQuestion,
  exerciseManagement,
  subjectTableHeader,
};
