import { t } from "@/core/i18n";
import { shallowRef } from "vue";

const API_URL = "/v1.0/management/Stages";

const MODULE_TITLE = t("quiz-management");

const NAME = "stages";

export default {
  API_URL,
  NAME,
  MODULE_TITLE,
};
