<template>
  <!-- outlet pages -->
  <div v-if="!isAuthDataReady">loading...</div>
  <RouterView v-else />
  <!--  -->
  <ConnectionStatus />
  <!-- models -->
  <DeleteModal />
  <SuccessModal />
  <AlertModal />
  <ConfirmationModel />
  <ExportFileModal />
  <!--  -->
</template>
<script setup lang="ts">
import ConnectionStatus from "@/core/components/layout/ConnectionStatus.vue";
import { onMounted, ref } from "vue";
import { setLocale } from "@/core/utils/functions";
import DeleteModal from "@/core/components/utils/modals/DeleteModal.vue";
import SuccessModal from "@/core/components/utils/modals/SuccessModal.vue";
import AlertModal from "@/core/components/utils/modals/AlertModal.vue";
import ExportFileModal from "@/core/components/utils/modals/ExportFileModal.vue";
import ConfirmationModel from "./core/components/utils/modals/ConfirmationModel.vue";
import { firebaseAuth } from "@/core/utils/firebase";

const isAuthDataReady = ref(false);

onMounted(() => {
  setLocale(localStorage.getItem("locale") || "en");

  firebaseAuth.authStateReady().then(() => {
    isAuthDataReady.value = true;
  });
});
</script>
<style lang="scss"></style>
