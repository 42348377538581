<template>
  <aside
    name="notifications-section"
    :class="{
      'absolute h-fit  ltr:right-0 rtl:left-0': !is_active,
      'w-auto h-full ': is_active,
    }"
    class="overflow-y-auto hidden lg:block hiddenScrollbar bg-white transition-all duration-150"
  >
    <div class="overflow-scroll hiddenScrollbar flex flex-col gap-5">
      <div class="flex items-center justify-between gap-2 p-5">
        <button @click="clearAll" class="text-primary text-xs font-light">
          {{ $t("clear-all") }}
        </button>
        <div class="flex items-center text-primaryDark gap-3">
          <p class="text-xs">{{ $t("live-notifications") }}</p>
          <Switch
            :is-active="is_active"
            @click="$emit('toggleNotificationBar')"
          />
        </div>
      </div>
      <!--  -->
      <template v-if="is_active && dataList">
        <TransitionGroup tag="ul" name="list">
          <li
            v-for="(item, index) in dataList"
            :key="index"
            class="bg-secondary transition-all duration-150 rounded-2xl p-3 m-3 text-primaryDark flex flex-col gap-3 select-none"
          >
            <div class="flex items-center justify-between gap-2">
              <div class="truncate overflow-clip">
                <p class="text-sm">{{ item.title }}</p>
                <p class="text-xs text-primary">
                  {{ moment(item.createdOn).fromNow() }}
                </p>
              </div>
              <button @click="() => clearItem(item.id)" class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.625"
                  height="10.625"
                  viewBox="0 0 10.625 10.625"
                >
                  <path
                    id="Icon_ionic-md-close"
                    data-name="Icon ionic-md-close"
                    d="M18.148,8.586,17.086,7.523l-4.25,4.25-4.25-4.25L7.523,8.586l4.25,4.25-4.25,4.25,1.062,1.062,4.25-4.25,4.25,4.25,1.062-1.062-4.25-4.25Z"
                    transform="translate(-7.523 -7.523)"
                    fill="#215085"
                  />
                </svg>
              </button>
            </div>
            <p class="text-xs font-light">
              {{ item.content }}
            </p>
          </li>
        </TransitionGroup>
      </template>
      <!--  -->
    </div>
  </aside>
</template>
<script lang="ts" setup>
// import { db } from "@/core/utils/firebase";
import { onMounted, onUnmounted, ref, watch } from "vue";
import moment from "moment";
import {
  ref as realTimeDbRef,
  onValue,
  remove,
  getDatabase,
  query,
  orderByChild,
  limitToLast,
} from "firebase/database";
import { db } from "@/core/utils/firebase";
import { useToast } from "vue-toastification";

defineProps<{
  is_active: boolean;
}>();

defineEmits(["toggleNotificationBar"]);
const toast = useToast();

interface LiveNotification {
  id: string;
  title: string;
  content: string;
  createdOn: string;
  byUserId: string;
}

const dataList = ref<LiveNotification[]>([]);

onMounted(() => {
  const events = query(
    realTimeDbRef(db, "Events"),
    orderByChild("createdOn"),
    limitToLast(25)
  );
  onValue(
    events,
    (snapshot) => {
      const val = snapshot.val();
      if (val) {
        for (let [key, value] of Object.entries(val)) {
          if (!dataList.value.some((item) => item.id == key)) {
            const eventObj: LiveNotification = {
              id: key,
              title: (value as any).title,
              content: (value as any).content,
              createdOn: (value as any).createdOn,
              byUserId: (value as any).byUserId,
            };
            dataList.value.push(eventObj);
          }
        }
        dataList.value.sort((a, b) => {
          return moment(b.createdOn).diff(moment(a.createdOn));
        });
      }
    },
    (error) => {
      toast.error("Error: " + error.message);
      dataList.value = [];
    }
  );
});

const clearAll = () => {
  dataList.value = [];
};

const clearItem = (id: string) => {
  const events = realTimeDbRef(db, "Events/" + id);
  remove(events);
  dataList.value = dataList.value.filter((item) => item.id != id);
};

watch(
  dataList,
  (newVal) => {
    if (newVal.length > 0) {
      // fade out the last item in the queue after 1 minute
      setTimeout(() => {
        clearItem(newVal[newVal.length - 1].id);
      }, 120000);
    }
  },
  { deep: true }
);
</script>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
