<template>
    <Modal :is_visible="storeModal.state.successModal.isVisiable" @close="() => storeModal.state.successModal.onCancel()" size="sm">
        <template #body>

            <div class="flex flex-col gap-4">

                <div class="flex items-start justify-between ">
                    <div class="flex flex-col gap-3 text-tertiaryDark">
                        <h2 class="text-xl ">{{  storeModal.state.successModal.title }}</h2>
                        <p>{{  storeModal.state.successModal.message }}</p>
                    </div>
                    <Image :src="successModalIcon" alt="successModalIcon" loading="lazy" />
                </div>


                <div class="flex items-center justify-center gap-3">
                    <button class="button-primary !w-fit !px-12" @click="() => storeModal.state.successModal.onCancel()">
                        {{ $t('got-it') }}
                    </button>
                </div>
            </div>


        </template>
    </Modal>
</template>
<script lang="ts" setup>
import successModalIcon from "@/assets/icons/successModalIcon.svg"

import { useModal } from "./store/modalStore";

const storeModal = useModal();


</script>