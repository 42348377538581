import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Audits";

const NAME = "SoftDeletedManagement";

const tableHeader: LabelVal[] = [
  {
    label: t("ID"),
    value: "id",
  },
  {
    label: t("type"),
    value: "type",
  },
  {
    label: t("reason"),
    value: "reason",
  },
  {
    label: t('by'),
    value: "By",
  },
  {
    label: t('date-of-delete'),
    value: "DateOfDelete",
  },
  {
    label: t("actions"),
    value: "actions",
  },
];

export default {
  tableHeader,
  NAME,
  API_URL,
};
