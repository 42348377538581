<template>
  <Modal
    :is_visible="storeModal.state.exportFileModal.isVisiable"
    @close="() => storeModal.state.exportFileModal.onCancel()"
    size="sm"
  >
    <template #body>
      <div class="flex flex-col gap-12">
        <div class="flex items-start justify-between">
          <div class="flex flex-col gap-3 text-tertiaryDark">
            <h2 class="text-xl">
              {{
                storeModal.state.exportFileModal.title || $t("export-list-as")
              }}
            </h2>
            <p>
              {{
                storeModal.state.exportFileModal.message ||
                $t("you-are-about-to-export-a-list-of-records")
              }}
            </p>
          </div>
          <Image :src="exportFileIcon" alt="delete" loading="lazy" />
        </div>

        <div class="flex items-start justify-between gap-2">
          <button
            v-for="item in [
              { title: 'CSV', value: ReportFileType.Csv, icon: csvIcon },
              { title: 'PDF', value: ReportFileType.Pdf, icon: pdfIcon },
              { title: 'Word', value: ReportFileType.Word, icon: wordIcon },
              { title: 'Excel', value: ReportFileType.Excel, icon: excelIcon },
            ]"
            :key="item.title"
            @click="() => (storeModal.state.exportFileModal.type = item.value)"
            type="button"
            :disabled="item.value != ReportFileType.Excel"
            :class="storeModal.state.exportFileModal.type == item.value ? 'border border-primary bg-tertiary' : 'bg-white'"
            class="flex flex-col gap-8 items-center justify-between h-[140px] shadow-lg px-12 py-8  rounded-xl text-sm text-primaryDark"
          >
            <img :src="item.icon" alt="" />

            <span>{{ item.title }}</span>
          </button>
        </div>

        <div class="flex items-center self-end justify-end w-1/2 gap-3">
          <button
            class="button-primary !bg-white border border-primary !text-primary"
            @click="() => storeModal.state.exportFileModal.onCancel()"
            type="button"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="button-primary !border-primary !bg-primary"
            @click="() => storeModal.state.exportFileModal.onConform()"
            type="button"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import exportFileIcon from "@/assets/icons/exportFileIcon.svg";
import wordIcon from "@/assets/icons/word.svg";
import excelIcon from "@/assets/icons/excel.svg";
import pdfIcon from "@/assets/icons/pdf.svg";
import csvIcon from "@/assets/icons/csv.svg";

import { useModal } from "./store/modalStore";
import { ReportFileType } from "@/core/enums";

const storeModal = useModal();
</script>
