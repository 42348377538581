import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Subjects";

const MODULE_TITLE = t("list-of-subjects");

const NAME = "subjects";

const settings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: false,
  rowsOptions: {
    hasView: false,
    hasBlock: false,
    hasDelete: false,
    hasEdit: true,
  },
};

const tableHeader = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("subject-name-ar"),
    value: "SubjectNameAr",
    sortKey: "ArabicName",
  },
  {
    label: t("subject-name-en"),
    value: "SubjectNameEn",
    sortKey: "EnglishName",
  },
  {
    label: t("no-of-published-pools"),
    value: "noOfPublishedPools",
  },
  {
    label: t("no-of-questions-0"),
    value: "noOfQuestions",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
};
